import { useTranslation } from 'react-i18next';

import { Content, Status, Wrapper } from './styles';
import { RewardStatusIcon } from './RewardStatusIcon';

import { Icon } from 'components/elements/Icon';

interface IRewardStatusProps {
  isActive: boolean;
  rewardTrackingSource: string;
}

export const RewardStatus = ({
  isActive,
  rewardTrackingSource,
}: IRewardStatusProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Content isActive={isActive}>
        <RewardStatusIcon
          isActive={isActive}
          rewardTrackingSource={rewardTrackingSource}
        />

        <Status isActive={isActive}>
          {isActive ? t('common.status.active') : t('common.status.inactive')}
        </Status>

        {!isActive && <Icon iconName="info" size={14} />}
      </Content>
    </Wrapper>
  );
};
