export const CAMPAIGN_TYPES = {
  regular: 'regular',
  instore: 'instore',
};

export const ZAKAT_TYPES = {
  pendapatan: 'pendapatan',
  saham: 'saham',
  fitrah: 'fitrah',
};

export const REWARD_TRACKING_SOURCES = {
  ctaLink: 'cta_link',
  linkedCard: 'linked_card',
  roundUpsAccount: 'round_ups_account',
} as const;
