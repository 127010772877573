import { Icon } from 'components/elements/Icon';
import { REWARD_TRACKING_SOURCES } from 'store/rewards/api/consts';

interface IRewardStatusIconProps {
  isActive: boolean;
  rewardTrackingSource: string;
}

export const RewardStatusIcon = ({
  isActive,
  rewardTrackingSource,
}: IRewardStatusIconProps) => {
  const isSourceTypeRoundUp =
    rewardTrackingSource === REWARD_TRACKING_SOURCES.roundUpsAccount;

  const isSourceTypeCard =
    rewardTrackingSource === REWARD_TRACKING_SOURCES.linkedCard;

  if (isSourceTypeRoundUp) {
    return <Icon iconName={isActive ? 'bankGreen' : 'rewardBank'} size={12} />;
  }

  if (isSourceTypeCard) {
    return (
      <Icon
        iconName={isActive ? 'rewardCardGreen' : 'rewardInactive'}
        size={12}
      />
    );
  }

  return null;
};
