import styled from '@emotion/styled';

import { BaseModalWrapper } from 'components/elements/ModalWrapper';
import { DEFAULT_MODAL_WIDTH } from 'components/elements/ModalWrapper/const';

export const ModalWrapper = styled(BaseModalWrapper)`
  ${({ theme }) => theme.mediaQuery(theme.breakpoints.mobile)} {
    max-width: ${DEFAULT_MODAL_WIDTH}px;
  }
  max-width: 70%;
  height: 80%;
`;
