import { getRegistrationQuestionKeys } from 'utils/getRegistrationQuestionKeys';

export const QUESTIONS_KEY = getRegistrationQuestionKeys();

export const SUPER_QUESTIONS_SOURCE = [
  QUESTIONS_KEY.question_1,
  QUESTIONS_KEY.question_2,
  QUESTIONS_KEY.question_3,
  QUESTIONS_KEY.question_4,
  QUESTIONS_KEY.super_question_5,
  QUESTIONS_KEY.super_question_6,
  QUESTIONS_KEY.super_question_7,
];

export const SIMPLE_QUESTIONS_SOURCE = [
  QUESTIONS_KEY.question_1,
  QUESTIONS_KEY.question_2,
  QUESTIONS_KEY.question_3,
  QUESTIONS_KEY.question_4,
  QUESTIONS_KEY.super_question_5,
  QUESTIONS_KEY.super_question_6,
  QUESTIONS_KEY.super_question_7,
];
