import { OFFER_TYPES } from '../consts';

import { REWARD_TRACKING_SOURCES } from 'store/rewards/api/consts';
import { IUserOffer } from 'store/rewards/api/types';

export const getOfferType = (offer?: IUserOffer) => {
  const { rewardTrackingSource } = offer || {};

  return rewardTrackingSource === REWARD_TRACKING_SOURCES.linkedCard ||
    rewardTrackingSource === REWARD_TRACKING_SOURCES.roundUpsAccount
    ? OFFER_TYPES.auto
    : OFFER_TYPES.online;
};
