import { InView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

import { RewardsListItem } from './components/RewardsListItem/RewardsListItem';
import { Wrapper } from './styles';
import { RewardsSkeleton } from './components/RewardsSkeleton/RewardsSkeleton';

import { useFavouriteOffersQuery } from 'store/rewards/api';
import FallbackMessage from 'components/elements/FallbackMessage/FallbackMessage';
import { IUserOffersResponse } from 'store/rewards/api/types';
import { useRewardsCurrentTab } from 'pages/NewRewards/hooks/useRewardsCurrentTab';
import { getOfferType } from 'pages/NewRewards/utils/getOfferType';

interface IRewardsListProps {
  isFavouriteCategory?: boolean;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  categoryName: string;
  offers: {
    data: IUserOffersResponse;
    isFetching: boolean;
  };
}

export const RewardsList = ({
  isFavouriteCategory,
  pageNumber,
  setPageNumber,
  categoryName,
  offers,
}: IRewardsListProps) => {
  const { t } = useTranslation();

  const favouriteOffers = useFavouriteOffersQuery();

  const { currentOfferType } = useRewardsCurrentTab();

  const filteredFavouriteOffers = favouriteOffers?.data?.favourites?.filter(
    (offer) => getOfferType(offer) === currentOfferType,
  );

  const setInView = (inView: boolean) => {
    const totalPages = offers?.data?.totalPages;

    if (
      inView &&
      totalPages &&
      totalPages > pageNumber &&
      !isFavouriteCategory
    ) {
      setPageNumber(pageNumber + 1);
    }
  };

  const currentData = isFavouriteCategory
    ? filteredFavouriteOffers
    : offers?.data?.records;

  if ((offers.isFetching && pageNumber === 1) || favouriteOffers.isLoading) {
    return <RewardsSkeleton />;
  }

  if (currentData?.length === 0) {
    return (
      <FallbackMessage
        text={t('newRewardsPage.notFound', { name: categoryName })}
      />
    );
  }

  return (
    <Wrapper offersCount={currentData?.length || 0}>
      {currentData?.map((reward) => (
        <RewardsListItem
          key={reward.id}
          reward={reward}
          isFavouriteCategory={isFavouriteCategory}
          setPageNumber={setPageNumber}
        />
      ))}

      <InView onChange={setInView} />
    </Wrapper>
  );
};
