import styled from '@emotion/styled';

export const AgreeText = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.mirageLight};
    font-family: ${theme.fontFamily.textRegular};
    font-size: ${theme.fontSizes['4sm']};
    letter-spacing: ${theme.letterSpacing.tight};
    line-height: ${theme.lineHeights.length['3sm']};
  `};
  width: 100%;
  text-align: left;
`;
