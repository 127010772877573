import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { FASTLINK_CONTAINER_ID, FASTLINK_FLOWS } from '../consts';
import { IFastlinkContentProps } from '../types';

import {
  useYodleeFastLinkQuery,
  useYodleeProviderAccountsMutation,
} from 'store/yodlee/yodlee';
import {
  setIsBankAccountLinkingStep,
  setBankAccountData,
} from 'store/fundingAccount/actions';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { displayErrorModal } from 'store/modals/actions';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  selectCurrentBank,
  selectFastlinkFlow,
} from 'store/fundingAccount/selectors';

export const useFastlinkContent = ({
  toggleFastlinkModal,
  type,
}: IFastlinkContentProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data, isFetching } = useYodleeFastLinkQuery();
  const [yodleeProviderAccounts] = useYodleeProviderAccountsMutation();
  const bank = useAppSelector(selectCurrentBank);
  const fastlinkFlow = useAppSelector(selectFastlinkFlow);

  useEffect(() => {
    if (
      data &&
      (fastlinkFlow === FASTLINK_FLOWS.manageConsent ||
        (bank && Object.keys(bank).length > 0))
    ) {
      window.fastlink.open(
        {
          fastLinkURL: data.fastlink.url,
          accessToken: `Bearer ${data.access_token}`,
          params: {
            configName: data.fastlink.config_name,
            flow: fastlinkFlow,
            ...(fastlinkFlow === FASTLINK_FLOWS.add && {
              providerId: bank?.site_id,
            }),
            ...((fastlinkFlow === FASTLINK_FLOWS.edit ||
              fastlinkFlow === FASTLINK_FLOWS.refresh) && {
              providerAccountId: bank?.account_id,
            }),
          },
          onSuccess: ({ providerAccountId }: { providerAccountId: string }) => {
            window.fastlink.close();

            toggleFastlinkModal();

            yodleeProviderAccounts({
              institutionId: bank?.institution_id,
              providerAccountId,
              type,
            }).then((response) => {
              if ('data' in response) {
                dispatch(setBankAccountData(response.data));

                dispatch(setIsBankAccountLinkingStep(true));
              }
            });
          },
          onError: (e) => {
            dispatch(
              displayErrorModal({ errorMessage: e.reason || e.message || '' }),
            );
          },
          onClose: () => toggleFastlinkModal(),
        },
        FASTLINK_CONTAINER_ID,
      );
    }

    return () => {
      window.fastlink?.close();
    };
  }, [
    bank,
    data,
    navigate,
    yodleeProviderAccounts,
    toggleFastlinkModal,
    fastlinkFlow,
    dispatch,
    type,
  ]);

  return {
    isFetching,
  };
};
