import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { OFFER_SOURCES, REWARDS_SEARCH_PARAMS } from '../consts';

import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { toggleInfoModal } from 'store/modals/actions';
import { IUserOffer } from 'store/rewards/api/types';
import { PATHS } from 'constants/paths';
import { REWARD_TRACKING_SOURCES } from 'store/rewards/api/consts';

export const useAutoRewardInfoModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleOpenAutoRewardsInfoModal = (offer: IUserOffer) => {
    const key =
      offer?.rewardTrackingSource === REWARD_TRACKING_SOURCES.linkedCard
        ? OFFER_SOURCES.card
        : OFFER_SOURCES.account;

    dispatch(
      toggleInfoModal({
        isInfoModalVisible: true,
        config: {
          title: t(
            'newRewardsPage.newRewardInternal.automaticRewardInfoModal.title.inactive',
          ),
          description: t(
            `newRewardsPage.newRewardInternal.automaticRewardInfoModal.description.inactive.${key}`,
          ),
          acceptButtonText: t(
            `newRewardsPage.newRewardInternal.automaticRewardInfoModal.button.${key}`,
          ),
          onAccept: () => {
            navigate({
              pathname: PATHS.rewards.linked,
              search: createSearchParams({
                [REWARDS_SEARCH_PARAMS.tab]: key,
              }).toString(),
            });
          },
        },
      }),
    );
  };

  return {
    handleOpenAutoRewardsInfoModal,
  };
};
