import { OFFER_TYPES } from '../consts';

import { REWARD_TRACKING_SOURCES } from 'store/rewards/api/consts';

export const getRewardsTrackingSources = (offerType: string) => {
  if (offerType === OFFER_TYPES.online) {
    return [REWARD_TRACKING_SOURCES.ctaLink];
  }

  if (offerType === OFFER_TYPES.auto) {
    return [
      REWARD_TRACKING_SOURCES.linkedCard,
      REWARD_TRACKING_SOURCES.roundUpsAccount,
    ];
  }

  return null;
};
