import React from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import { useRegistrationQuestionsTransComponent } from './useRegistrationQuestionsTransComponent';
import { SUPER_QUESTIONS_SOURCE, SIMPLE_QUESTIONS_SOURCE } from './consts';
import { AgreeText } from './styles';

import {
  REGISTRATION_PERSONAL_ADDRESS,
  REGISTRATION_PERSONAL_TAXRESIDENT,
} from 'store/user/consts';
import DeprecatedDotsLoader from 'components/elements/DeprecatedDotsLoader/DeprecatedDotsLoader';
import CheckboxFF from 'components/elements/CheckboxFF/CheckboxFF';
import { AU_APP, MY_APP } from 'constants/localeConfigs';
import { SESSION_STORAGE_KEYS } from 'constants/sessionStorageKeys';
import { REGISTRATION_BANKS_FUNDING_ACCOUNT } from 'store/commonTypes';
import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import mys from 'i18n/locales/ms-ms.json';

const useValidation = () => {
  const { t } = useTranslation();

  const validate = (values) => {
    const errors = {};

    const requiredText = t('fields.validation.required');

    if (!values.question_1?.length > 0) {
      errors.question_1 = requiredText;
    }

    if (!values.question_2?.length > 0) {
      errors.question_2 = requiredText;
    }

    if (!values.question_3?.length > 0) {
      errors.question_3 = requiredText;
    }

    if (!values.question_4?.length > 0) {
      errors.question_4 = requiredText;
    }

    if (!MY_APP) {
      if (!values.super_question_5?.length > 0) {
        errors.super_question_5 = requiredText;
      }

      if (!values.super_question_6?.length > 0) {
        errors.super_question_6 = requiredText;
      }

      if (!values.super_question_7?.length > 0) {
        errors.super_question_7 = requiredText;
      }
    }

    return errors;
  };

  return { validate };
};

export default function AccountQuestions({
  handleSubmit,
  errorMessage,
  goBack,
  showLoader,
  user,
}) {
  const { t } = useTranslation();
  const { validate } = useValidation();

  const getRegistrationQuestionTransComponent =
    useRegistrationQuestionsTransComponent();

  const isSmsf =
    sessionStorage.getItem(SESSION_STORAGE_KEYS.agreementType) === 'superFund';

  const prevPage =
    AU_APP && isSmsf
      ? REGISTRATION_BANKS_FUNDING_ACCOUNT
      : REGISTRATION_PERSONAL_ADDRESS;

  let hideBack = false;

  if (AU_APP && isSmsf && user && user.user) {
    if (user.user.alerts === null) {
      hideBack = true;
    }

    if (user.user.alerts) {
      const types = user.user.alerts.map((a) => a.type);

      hideBack =
        types.indexOf('REGISTRATION_INCOMPLETE_LINKFUNDING') === -1 &&
        types.indexOf('REGISTRATION_INCOMPLETE_LINKSPENDING') === -1;
    }
  }

  const questionsSource = AU_APP
    ? SIMPLE_QUESTIONS_SOURCE
    : Object.keys(mys.registrationPage.accountQuestions.questions);

  const questions = isSmsf ? SUPER_QUESTIONS_SOURCE : questionsSource;

  return (
    <Form
      onSubmit={handleSubmit}
      validate={(values) => validate(values)}
      render={({ form, values, hasValidationErrors }) => (
        <form
          className="form"
          onSubmit={(e) => handleSubmit(e, values, form, hasValidationErrors)}
        >
          <AgreeText>
            {t('registrationPage.accountQuestions.disclaimerTop')}
          </AgreeText>

          <div className={`"form-field radio ${MY_APP ? '-align-text' : ''}`}>
            <div className="form-field__sub-input-link">
              <div className={`radio-grouped ${MY_APP ? '-active-text' : ''}`}>
                {questions.map((question, index) => (
                  <CheckboxFF
                    key={question}
                    name={question}
                    type="checkbox"
                    defaultChecked={values?.[question]?.length > 0}
                    label={
                      <Trans
                        t={t}
                        i18nKey={`registrationPage.accountQuestions.questions.${question}`}
                        components={
                          MY_APP && getRegistrationQuestionTransComponent(index)
                        }
                      />
                    }
                  />
                ))}

                <div className="hide">{errorMessage}</div>
              </div>

              {AU_APP && (
                <AgreeText>
                  {t('registrationPage.accountQuestions.disclaimerBottom')}
                </AgreeText>
              )}

              {showLoader ? (
                <div className="buttons-part">
                  <div className="form-field">
                    <DeprecatedButton
                      title={<DeprecatedDotsLoader additionalClass="btn" />}
                      buttonClass="button"
                      handleClick={() => {}}
                    />
                  </div>

                  <div className="form-field">
                    <DeprecatedButton
                      title={<DeprecatedDotsLoader additionalClass="btn" />}
                      buttonClass="button button--submit"
                      handleClick={() => {}}
                    />
                  </div>
                </div>
              ) : (
                <div className="buttons-part">
                  {!hideBack && (
                    <div className="form-field">
                      <DeprecatedButton
                        title={t('common.back')}
                        buttonClass="button back"
                        handleClick={() =>
                          goBack(
                            MY_APP
                              ? REGISTRATION_PERSONAL_TAXRESIDENT
                              : prevPage,
                          )
                        }
                      />
                    </div>
                  )}

                  <div className="form-field">
                    <DeprecatedButton
                      type="submit"
                      title={t('common.next')}
                      buttonClass="button button--submit"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      )}
    />
  );
}

AccountQuestions.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    user: PropTypes.shape({
      alerts: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  errorMessage: PropTypes.string,
  goBack: PropTypes.func.isRequired,
  showLoader: PropTypes.bool,
};

AccountQuestions.defaultProps = {
  errorMessage: '',
  showLoader: false,
};
