import { MIGRATION_FLOW } from 'constants/migrationConstants';
import aus from 'i18n/locales/en-au.json';

export type IInsuranceQuestions =
  typeof aus.registrationPage.accountMigration.insurancePage.questions;
export type IInsuranceQuestionsKey = keyof IInsuranceQuestions;

export type IInsuranceQuestionsDictionary = {
  [key in IInsuranceQuestionsKey]: IInsuranceQuestionsKey;
};

export const INSURANCE_QUESTIONS_KEY = Object.keys(
  aus.registrationPage.accountMigration.insurancePage.questions,
).reduce(
  (acc, questionKey) => ({
    ...acc,
    [questionKey]: questionKey,
  }),
  {} as IInsuranceQuestionsDictionary,
);

export type IMigrationInsuranceQuestions = {
  [MIGRATION_FLOW.superEstate]: IInsuranceQuestionsKey[];
  [MIGRATION_FLOW.standard]: IInsuranceQuestionsKey[];
};

export const INSURANCE_QUESTIONS = {
  [MIGRATION_FLOW.superEstate]: [
    INSURANCE_QUESTIONS_KEY.superEstateQuestion_1,
    INSURANCE_QUESTIONS_KEY.question_2,
    INSURANCE_QUESTIONS_KEY.superEstateQuestion_4,
    INSURANCE_QUESTIONS_KEY.question_5,
  ],
  [MIGRATION_FLOW.standard]: [
    INSURANCE_QUESTIONS_KEY.question_3,
    INSURANCE_QUESTIONS_KEY.question_6,
  ],
} as IMigrationInsuranceQuestions;

export const OPTIONAL_INSURANCE_QUESTION = 'super_annuation_insurance';
