import aus from 'i18n/locales/en-au.json';

export type IAccountQuestions =
  typeof aus.registrationPage.accountQuestions.questions;

export type IAccountQuestionsDictionary = {
  [key in keyof IAccountQuestions]: keyof IAccountQuestions;
};

export const getRegistrationQuestionKeys = () =>
  Object.keys(aus.registrationPage.accountQuestions.questions).reduce(
    (acc, questionKey) => ({
      ...acc,
      [questionKey]: questionKey,
    }),
    {} as IAccountQuestionsDictionary,
  );
