import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { generatePath, useNavigate } from 'react-router-dom';
import { InView } from 'react-intersection-observer';
import '../../../styles/pages/reward-internal.scss';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import Input from '../../../components/elements/Input/Input';
import Loader from '../../../components/elements/Loader/Loader';
import ChangeLocationModal from '../../../components/layout/Modals/ChangeLocationModal';
import { FAVOURITE_CATEGORY_ID, OFFER_TYPES } from '../consts';
import { useAutoRewardInfoModal } from '../hooks/useAutoRewardInfoModal';
import { getRewardsTrackingSources } from '../utils/getRewardsTrackingSources';
import { getOfferType } from '../utils/getOfferType';

import CategoriesView from './CategoriesView';
import { RewardStatus } from './RewardStatus/RewardStatus';
import { RewardSearchItem } from './styles';

import DeprecatedButton from 'components/elements/Deprecated__Button/Button';
import { useFavouriteOffersQuery, useOffersQuery } from 'store/rewards/api';
import { PATHS } from 'constants/paths';

function offerRender(offerData, t) {
  const {
    offer,
    isInstore,
    toggleLikeOffer,
    isShowFavourite,
    isLastOffer,
    setInView,
    handleOfferClick,
  } = offerData;

  let distance = '';

  if (
    isInstore &&
    offer.locations &&
    offer.locations[0] &&
    offer.locations[0].distance
  ) {
    if (offer.locations[0].distance < 1) {
      distance = (
        <span>
          {parseInt(offer.locations[0].distance * 1000)}

          {t('common.metric.meter')}
        </span>
      );
    } else if (isInstore) {
      distance = (
        <span>
          {parseFloat(offer.locations[0].distance).toFixed(1)}

          {t('common.metric.kilometerShort')}
        </span>
      );
    }
  }

  return (
    <div className="rewards-block" key={offer.id}>
      <RewardSearchItem type="button" onClick={() => handleOfferClick(offer)}>
        <div className="grid__container-item">
          <div className="grid__container-image">
            <div
              className="grid__container-logo"
              style={{
                background: `url(${offer.coverArt}) no-repeat`,
                backgroundSize: 'cover',
              }}
            />
          </div>

          <div className="grid__container-bottom-part">
            <div className="brand-icon-placeholder" />

            <div
              className="brand-icon"
              style={{
                background: `url(${offer.logo}) no-repeat`,
                backgroundSize: 'cover',
              }}
            />

            <div className="brand-title-block reward-search-title">
              <div>{offer.advertiser}</div>

              <div>{offer.title}</div>
            </div>

            {getOfferType(offer) === OFFER_TYPES.auto && (
              <RewardStatus isActive={offer.sourceActive} />
            )}

            <div className="distance">
              <div className="marker-icon">
                <div>{distance}</div>
              </div>
            </div>
          </div>
        </div>
      </RewardSearchItem>

      <div
        className={
          offer.isFavourite || isShowFavourite
            ? 'like-instore'
            : 'unlike-instore'
        }
        onClick={() => {
          toggleLikeOffer({
            isFavourite: offer.isFavourite || isShowFavourite,
            id: offer.id,
            isInstore: isShowFavourite
              ? !(offer.campaignType === OFFER_TYPES.online)
              : isInstore,
            isShowFavourite,
          });
        }}
        onKeyPress={() => {}}
        role="button"
        tabIndex="0"
      />

      {!isLastOffer && <InView onChange={setInView} />}
    </div>
  );
}

export default function RewardsSearchView({
  changeSearchList,
  instoreOffers,
  categories,
  filterCategory,
  categoryId,
  categoryName,
  toggleLikeOffer,
  isShowFavourite,
  showMainLoader,
  isInStoreRewards,
  address,
  openChangeLocation,
  isShowChangeLocation,
  currentSearchList,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState(1);

  const [searchValue, setSearchValue] = useState('');

  const offers = useOffersQuery(
    {
      rewardTrackingSource: getRewardsTrackingSources(currentSearchList),
      categoryId,
      pageNumber,
      query: searchValue,
    },
    {
      skip:
        !currentSearchList ||
        currentSearchList === OFFER_TYPES.inStore ||
        categoryId === FAVOURITE_CATEGORY_ID,
    },
  );

  const favouriteOffers = useFavouriteOffersQuery();

  const currentOffers = useMemo(() => {
    if (categoryId === FAVOURITE_CATEGORY_ID) {
      return favouriteOffers?.data?.favourites?.filter((offer) => {
        if (offer?.campaignType === OFFER_TYPES.online) {
          return getOfferType(offer) === currentSearchList;
        }

        return offer.campaignType === currentSearchList;
      });
    }

    if (
      currentSearchList === OFFER_TYPES.online ||
      currentSearchList === OFFER_TYPES.auto
    ) {
      return offers?.data?.records;
    }

    return instoreOffers?.records;
  }, [
    categoryId,
    currentSearchList,
    offers?.data?.records,
    favouriteOffers?.data?.favourites,
    instoreOffers?.records,
  ]);

  const onInputChange = debounce((e) => {
    setSearchValue(e.target.value);

    setPageNumber(1);
  }, 1000);

  const notFoundMessage = useMemo(() => {
    if (currentSearchList === OFFER_TYPES.online) {
      return t('newRewardsPage.rewardSearch.switchingToAutomaticInstore');
    }

    if (currentSearchList === OFFER_TYPES.auto) {
      return t('newRewardsPage.rewardSearch.switchingToOnlineInstore');
    }

    return t('newRewardsPage.rewardSearch.switchingToOnlineAutomatic');
  }, [currentSearchList, t]);

  const setInView = (inView) => {
    const totalPages = offers?.data?.totalPages;

    if (
      inView &&
      totalPages &&
      totalPages > pageNumber &&
      categoryId !== FAVOURITE_CATEGORY_ID
    ) {
      setPageNumber(pageNumber + 1);
    }
  };

  const { handleOpenAutoRewardsInfoModal } = useAutoRewardInfoModal();

  const handleOfferClick = (offer) => {
    if (currentSearchList === OFFER_TYPES.auto && !offer.sourceActive) {
      handleOpenAutoRewardsInfoModal(offer);

      return;
    }

    const pathname =
      !(
        currentSearchList === OFFER_TYPES.inStore ||
        categoryId === FAVOURITE_CATEGORY_ID
      ) || offer.campaignType === OFFER_TYPES.online
        ? PATHS.rewards.online.index
        : PATHS.rewards.inStore;

    navigate(
      generatePath(pathname, {
        id: offer.id || offer.offerId,
      }),
    );
  };

  const isSwitcherDisabled = offers?.isFetching || favouriteOffers?.isFetching;

  return (
    <div className="in-store rewards-search">
      <div className="profile-content settings__content-default-container">
        {!categoryId && !isInStoreRewards && (
          <div className="grid__title search-page">
            {!isShowFavourite && (
              <div className="search-content">
                <Input
                  onChange={onInputChange}
                  autoFocus={window.innerWidth > 768}
                  type="text"
                  placeholder={t(
                    'newRewardsPage.rewardSearch.searchPlaceholder',
                  )}
                  classList="grid__input"
                />
              </div>
            )}
          </div>
        )}

        {(categoryId || searchValue) && (
          <div>
            <div className="profile-content__header -with-navigation ">
              <div className="profile-content__title">
                {categoryName !== '' && <div>{categoryName}</div>}
              </div>

              <div className="profile-content__navigation">
                <DeprecatedButton
                  title={t('newRewardsPage.rewardSearch.online')}
                  buttonClass={
                    currentSearchList === OFFER_TYPES.online ? 'active' : ''
                  }
                  handleClick={() => changeSearchList(OFFER_TYPES.online)}
                  disabled={isSwitcherDisabled}
                />

                <DeprecatedButton
                  title={t('newRewardsPage.rewardSearch.automatic')}
                  buttonClass={
                    currentSearchList === OFFER_TYPES.auto ? 'active' : ''
                  }
                  handleClick={() => changeSearchList(OFFER_TYPES.auto)}
                  disabled={isSwitcherDisabled}
                />

                <DeprecatedButton
                  title={t('newRewardsPage.rewardSearch.inStore')}
                  buttonClass={
                    currentSearchList === OFFER_TYPES.inStore ? 'active' : ''
                  }
                  handleClick={() => changeSearchList(OFFER_TYPES.inStore)}
                  disabled={isSwitcherDisabled}
                />
              </div>

              {!isShowFavourite && (isInStoreRewards || categoryId) && (
                <div className="grid__title">
                  <div className="search-content">
                    <Input
                      onChange={onInputChange}
                      autoFocus={window.innerWidth > 768}
                      type="text"
                      placeholder={t(
                        'newRewardsPage.rewardSearch.searchPlaceholder',
                      )}
                      classList="grid__input"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="profile-content__container">
              <div className="grid">
                <div className="grid__categories">
                  {currentSearchList === OFFER_TYPES.inStore && (
                    <div className="change-location">
                      <div className="panel">
                        <div className="label">
                          {t('newRewardsPage.rewardSearch.offersNear')}
                        </div>

                        <div className="address">{address}</div>

                        <div
                          onClick={() => openChangeLocation(true)}
                          onKeyPress={() => {}}
                          role="button"
                          tabIndex="0"
                          className="change"
                        >
                          {t('newRewardsPage.rewardSearch.change')}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="grid__container" id="rewards-instore-items">
                    {!showMainLoader &&
                      currentOffers?.map((offer, index) =>
                        offerRender(
                          {
                            offer,
                            isInstore:
                              currentSearchList === OFFER_TYPES.inStore ||
                              categoryId === FAVOURITE_CATEGORY_ID,
                            toggleLikeOffer,
                            isLastOffer: currentOffers[index + 1],
                            setInView,
                            isShowFavourite:
                              categoryId === FAVOURITE_CATEGORY_ID,
                            handleOfferClick,
                          },
                          t,
                        ),
                      )}

                    {showMainLoader && (
                      <Loader additionalClass="-absolute -top" />
                    )}

                    {!showMainLoader && currentOffers?.length === 0 && (
                      <div className="found-block">
                        <div className="icon-not-found" />

                        <div className="not-found">
                          <div>
                            {t('newRewardsPage.rewardSearch.noResultsFound')}
                          </div>

                          <div>{notFoundMessage}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!categoryId &&
          !searchValue &&
          !isShowFavourite &&
          categories &&
          !showMainLoader && (
            <div className="profile-content__container empty-search">
              <div className="grid">
                <div className="grid__categories">
                  <div className="grid__title">
                    {t('newRewardsPage.rewardSearch.categories')}
                  </div>

                  <CategoriesView
                    categories={categories.categories}
                    filterCategory={filterCategory}
                    withCarusel={false}
                    setPageNumber={setPageNumber}
                  />
                </div>
              </div>
            </div>
          )}

        {isShowChangeLocation && (
          <ChangeLocationModal
            isShowChangeLocation={isShowChangeLocation}
            close={openChangeLocation}
            addressSave={address}
            isMap={false}
          />
        )}
      </div>
    </div>
  );
}

RewardsSearchView.propTypes = {
  instoreOffers: PropTypes.shape({
    records: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  categories: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  filterCategory: PropTypes.func.isRequired,
  categoryId: PropTypes.string.isRequired,
  categoryName: PropTypes.string.isRequired,
  toggleLikeOffer: PropTypes.func.isRequired,
  isShowFavourite: PropTypes.bool.isRequired,
  showMainLoader: PropTypes.bool.isRequired,
  isInStoreRewards: PropTypes.bool.isRequired,
  address: PropTypes.string.isRequired,
  openChangeLocation: PropTypes.func.isRequired,
  isShowChangeLocation: PropTypes.bool.isRequired,
  currentSearchList: PropTypes.string.isRequired,
  changeSearchList: PropTypes.func.isRequired,
};
